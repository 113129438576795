import {
  ALLERGY,
  CHILDREN,
  CITIZEN_MANAGEMENT,
  DASHBOARD,
  GROWTH_METRICS,
  HEAD_CIRCUMFERENCE_METRICS,
  MAPI,
  MEDICAL_FOLDER,
  MEDICAL_FOLDER_MANAGEMNT,
  VACCINATION,
  VACCINATION_MANAGEMENT,
  DCI,
  SUBSCRIPTION,
  SPECIALTIE,
  OCCASIONAL,
  SEASONAL,
  ANTIRABIQUE,
  ROUTINE,
} from "./AppPermissions";
import {
  CREATE,
  UPDATE,
  VIEW,
  DELETE /*, UNDELETE,VIEW_UPDATE, ACTIVATE, DEACTIVATE,*/,
  SEARCH,
  REGISTRED,
  LIST,
  VIEW_MENU,
  CLOSE,
  ACTIVATE,
  DEACTIVATE,
  ACCEPT,
  REJECT,
  UPDATE_PAST,
  VIEW_ABSENT_ANTIRABIQUE,
} from "./Permissions";

// Evax-ii-Middle	citizen_management	can_check_parent_existence

// Evax-ii-Middle	medical_folder_management	can_view_allergie_details

export const CitizenManagement = {
  module: {
    [CHILDREN]: {
      permissions: {
        [CREATE]: `${CITIZEN_MANAGEMENT}.can_add_children`,
        [SEARCH]: `${CITIZEN_MANAGEMENT}.can_search_children`,
        [REGISTRED]: `${CITIZEN_MANAGEMENT}.can_registred_children`,
        [CLOSE]: `${CITIZEN_MANAGEMENT}.can_close_medical_folder`,
      },
    },
    [SUBSCRIPTION]: {
      permissions: {
        [ACCEPT]: `${CITIZEN_MANAGEMENT}.can_validate_subscription_request`,
        [REJECT]: `${CITIZEN_MANAGEMENT}.can_validate_subscription_request`,
        [SEARCH]: `${CITIZEN_MANAGEMENT}.can_view_subscription_request_list`,
        [VIEW]: `${CITIZEN_MANAGEMENT}.can_view_subscription_request_list`,
        [UPDATE]: `${CITIZEN_MANAGEMENT}.can_edit_citizev`,

      },
    },
    [DASHBOARD]: {
      permissions: {
        [VIEW]: `${CITIZEN_MANAGEMENT}.can_view_dashboard`,
        // [LIST]: {
        //   governorate: `${CITIZEN_MANAGEMENT}.can_view_governorate_dashboard`,
        //   circonscription: `${CITIZEN_MANAGEMENT}.can_view_circonscription_dashboard`,
        //   center: `${CITIZEN_MANAGEMENT}.can_view_center_dashboard`,
        // },
      },
    },
  },
};

export const VaccinationManagement = {
  module: {
    [VACCINATION]: {
      permissions: {
        [CREATE]: {
          [ROUTINE]:`${VACCINATION_MANAGEMENT}.can_add_vaccination`,
          [OCCASIONAL]:`${VACCINATION_MANAGEMENT}.can_add_${OCCASIONAL}_vaccination`,
          [SEASONAL]:`${VACCINATION_MANAGEMENT}.can_add_${SEASONAL}_vaccination`,
          [ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_add_vaccination_${ANTIRABIQUE}`,

        },
        [DELETE]: {
          [ROUTINE]:`${VACCINATION_MANAGEMENT}.can_delete_vaccination`,
          [OCCASIONAL]:`${VACCINATION_MANAGEMENT}.can_delete_${OCCASIONAL}_vaccination`,
          [SEASONAL]:`${VACCINATION_MANAGEMENT}.can_delete_${SEASONAL}_vaccination`,
          [ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_delete_vaccination_${ANTIRABIQUE}`,

        },
        [UPDATE]:{
          [ROUTINE]:`${VACCINATION_MANAGEMENT}.can_edit_vaccination`,
          [OCCASIONAL]:`${VACCINATION_MANAGEMENT}.can_edit_${OCCASIONAL}_vaccination`,
          [SEASONAL]:`${VACCINATION_MANAGEMENT}.can_edit_${SEASONAL}_vaccination`,
          [ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_edit_vaccination_${ANTIRABIQUE}`,

        },
        [UPDATE_PAST]: `${VACCINATION_MANAGEMENT}.can_edit_past_vaccination`,
        [VIEW]: {
          [ROUTINE]:`${VACCINATION_MANAGEMENT}.can_view_vaccination`,
          [OCCASIONAL]:`${VACCINATION_MANAGEMENT}.can_view_${OCCASIONAL}_vaccination`,
          [SEASONAL]:`${VACCINATION_MANAGEMENT}.can_view_${SEASONAL}_vaccination`,
          [ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_view_vaccination_${ANTIRABIQUE}`,

        },
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_list`,
      },
    },
    [DCI]: {
      permissions: {
        [CREATE]: `${VACCINATION_MANAGEMENT}.can_add_dci`,
        [DELETE]: `${VACCINATION_MANAGEMENT}.can_delete_dci`,
        [UPDATE]: `${VACCINATION_MANAGEMENT}.can_edit_dci`,
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_dci_list`,
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_dci_list`,
        [VIEW_MENU]: `${VACCINATION_MANAGEMENT}.can_view_dci_menu`,
      },
    },
    [SPECIALTIE]: {
      permissions: {
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_specialite`,
        [LIST]: `${VACCINATION_MANAGEMENT}.can_view_list`,
        [VIEW_MENU]: `${VACCINATION_MANAGEMENT}.can_view_specialite_menu`,

      },
    },
    [DASHBOARD]: {
      permissions: {
        [VIEW]: `${VACCINATION_MANAGEMENT}.can_view_dashboard`,
        [LIST]: {
          governorate: `${VACCINATION_MANAGEMENT}.can_view_governorate_dashboard`,
          circonscription: `${VACCINATION_MANAGEMENT}.can_view_circonscription_dashboard`,
          center: `${VACCINATION_MANAGEMENT}.can_view_center_dashboard`,
        },
        [VIEW_ABSENT_ANTIRABIQUE]:`${VACCINATION_MANAGEMENT}.can_view_list_absent_antirabique`
      },
    },
  },
};

export const MedicalFolderManagement = {
  module: {
    [ALLERGY]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_allergies`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_allergies`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_allergies`,
        [DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_allergies`,
      },
    },
    [GROWTH_METRICS]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_growth_metrics`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_growth_metrics`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_growth_metrics`,
        [DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_growth_metrics`,
      },
    },
    [HEAD_CIRCUMFERENCE_METRICS]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_head_circumference_metrics`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_head_circumference_metrics`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_head_circumference_metrics`,
        [DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_head_circumference_metrics`,
      },
    },
    [MEDICAL_FOLDER]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_medical_folder`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_medical_folder`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_medical_folder`,
        [DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_medical_folder`,
      },
    },
    [MAPI]: {
      permissions: {
        [CREATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_add_mapi`,
        [UPDATE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_edit_mapi`,
        [VIEW]: `${MEDICAL_FOLDER_MANAGEMNT}.can_view_mapi`,
        [DELETE]: `${MEDICAL_FOLDER_MANAGEMNT}.can_delete_mapi`,
      },
    },
  },
};
export const LIST_REFERENTIEL = {
  module: "list",
  permissions: {
    [VIEW]: "view_list",
    [CREATE]: "add_list",
    [UPDATE]: "change_list",
    [ACTIVATE]: "activate_list",
    [DEACTIVATE]: "delete_list",
  },
};

// export const SPECIALTIE = {
//   module: "Specialtie",
//   permissions: {
//     [VIEW]: "view_specialtie",
//     [CREATE]: "add_specialtie",
//     [UPDATE]: "change_specialtie",
//     [ACTIVATE]: "activate_specialtie",
//     [DEACTIVATE]: "delete_specialtie",
//   },
// };
