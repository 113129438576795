import React, { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import { FieldError, useFieldCSSClasses } from "./FieldError"
import useHideAndShowField from "./hooks/useHideAndShowField"
import { isRLTLang } from "src/i18n"
import { getAttr } from "src/helpers"
import { isFunction } from "lodash"
const Counter = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  unit = '',
  inputGroupClassName = "form-group",
  inputClassName = "form-control",
  controller = undefined,
  siblingsControl = undefined,
  type = "text",
  required = false,
  disabled = false,
  maxLength = 256,
  form,
  size = 12,
  placeholder,
  loadValue,
  step = 1,
  enableInput = false,
  maxCounterValue = 20,
  ...props
}) => {

  const unitStyle = isRLTLang() ? {
    position: "absolute",
    height: '1.125rem',
    minWidth: '1.25rem',
    paddingTop: '.375rem',
    left: '1.375rem'
  } : {
    position: "absolute",
    height: '1.125rem',
    minWidth: '1.25rem',
    paddingTop: '.375rem',
    right: '1.375rem'
  }

  const formik = useFormikContext()
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name)
  const [counterValue, setCounterValue] = useState(0)



  useEffect(() => {
    let val;

    if (isFunction(loadValue)) {
      val = loadValue(formik.values)
      form.setFieldValue(field.name, val)
      formik.setFieldValue(field.name, val)
    }


    //  eslint-disable-next-line
  }, [formik.values])


  const deleteItem = () => {
    let counterItemValue = !counterValue ? 0 : counterValue

    if (counterItemValue <= 0)
      return
    setCounterValue(Math.round((counterItemValue - step) * 10) / 10);
    formik.setFieldValue(field.name,Math.round((counterItemValue - step) * 10) / 1)
    form.setFieldValue(field.name,Math.round((counterItemValue - step) * 10) / 10)

  }
  const addItem = () => {
    let counterItemValue = !counterValue ? 0 : counterValue

    if (controller && siblingsControl && counterItemValue +  getAttr(formik.values,siblingsControl) >= getAttr(formik.values,controller))
      return
    if (maxCounterValue && counterItemValue >= maxCounterValue)
      return
    setCounterValue( Math.round((counterItemValue + step) * 10) / 10);
    formik.setFieldValue(field.name,Math.round((counterItemValue + step) * 10) / 10 ) 
    form.setFieldValue(field.name,Math.round((counterItemValue + step) * 10) / 10 ) 

  }

  useEffect(()=>{
    console.log(formik.values)
  },[formik.values])

const handleChange=(e)=>{
  if(enableInput){
    let value = e.target.value;
    let parsedValue = parseFloat(value);

    // Round to one decimal place
    if (!isNaN(parsedValue)) {
      let roundedValue = Math.round(parsedValue * 10) / 10;
      setCounterValue(roundedValue);
      formik.setFieldValue(field.name,roundedValue ) 
      form.setFieldValue(field.name,roundedValue ) 
      return;
    }
    
    setCounterValue(parsedValue);
    formik.setFieldValue(field.name,value ) 
    form.setFieldValue(field.name,value )
    
  }
}

  return (
    <>
      <div style={{ width: '100%' }} className={inputGroupClassName + conditionalShowAndHideClassName + ' counter-container'}>
        {label && <label className="custom-label-style"> {label} <span>{(required && "*")}</span></label>}
        {unit && <div style={unitStyle}  >{unit} </div>}
        <div className="input-group">
          {!enableInput && <div className="input-group-prepend">
            <button className="btn btn-danger btn-outline-secondary" type="button" onClick={() => deleteItem()} disabled={disabled}>-</button>
          </div>}
          <input style={{ backgroundColor: '#FAFAFA', textAlign: 'center', minHeight: '40px' }}
            type={enableInput ? "number" : type}
            step={step ? step : ""}
            disabled={!enableInput}
            className={`${inputClassName} ${fieldCSSClasses}`}
            maxLength={maxLength}
            autoComplete="off"
            placeholder={placeholder}
            {...field}
            onChange={handleChange}
            value={field.value}
          />
          {!enableInput && <div className="input-group-prepend">
            <button className="btn btn-danger btn-outline-secondary" type="button" onClick={() => addItem()} disabled={disabled}>+</button>
          </div>}
        </div>
        <FieldError fieldName={field.name} />
      </div>
    </>

  )
}

export default Counter