import { useEffect } from "react";
import { get } from "lodash";
import moment from "moment";

const useResetDatePickerWithInput = ({ formik, fieldName, setSelectedDate, format }) => {
  useEffect(() => {
    // Retrieve the field value and end date from formik values
    const selectedDateValue = get(formik.values, fieldName, null);
    const endDateValue = get(formik.values, "endDate", null);

    // Ensure the selectedDateValue is valid before setting the state
    if (selectedDateValue) {
      const date = moment(selectedDateValue, format, true);
      if (date.isValid()) {
        setSelectedDate(date.toDate());
      } else {
        setSelectedDate(null);
      }
    } else {
      setSelectedDate(null);
    }
  }, [formik.values[fieldName], formik.values["endDate"], format, setSelectedDate]); // Use stable references for dependencies

  useEffect(() => {
    const selectedDateValue = get(formik.values, fieldName, null);
    if (!selectedDateValue) {
      setSelectedDate(null);
    }
  }, [formik.values[fieldName], formik.values["endDate"], setSelectedDate]);
};

export default useResetDatePickerWithInput;
