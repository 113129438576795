import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import InputMaskBase from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import { FieldError, useFieldCSSClasses } from "./FieldError";
import useHideAndShowField from "./hooks/useHideAndShowField";
import { FormattedError } from "../../alerts/FormattedError";
import { clearChildren } from "src/modules/admin/containers/childrenSpace/store/actions";
import _ from "lodash";

const InputMask = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  inputGroupClassName = "form-group",
  inputClassName = "form-control",
  mask,
  error,
  disabled = false,
  setError,
  size = 12,
  type = "text",
  backgroundColor = "#FAFAFA",
  required = false,
  emit,
  action,
  ...props
}) => {
  const formik = useFormikContext();
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });
  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name);
  useEffect(() => {
    if (_.isFunction(setError) && error) {
      setError(null);
    }
    if (_.isFunction(emit)){
      emit({type:`CHANGE_${field.name.toUpperCase()}`,payload:field.value})
      console.log(`CHANGE_${field.name.toUpperCase()}`,field.value)
    }
  }, [field.value]);

  return (
    <div
      className={
        inputGroupClassName +
        conditionalShowAndHideClassName +
        ` col-lg-${size}`
      }
    >
      {label && (
        <label className="custom-label-style">
          {" "}
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <InputMaskBase
        disabled={disabled}
        mask={mask}
        {...field}
        value={field.value || ""}
      >
        {(inputProps) => (
          <MaterialInput
            {...inputProps}
            // dir="ltr"
            className={`${inputClassName} ${fieldCSSClasses}  ${
              disabled && `disabled`
            }`}
            autoComplete="off"
            disableUnderline
            style={{font: "inherit", height: "40px", backgroundColor: "#FAFAFA", color:"#181c32" }}
            {...props}
          />
        )}
      </InputMaskBase>
      {error ? <FormattedError message={error} onClose={null} /> : <></>}
      <FieldError fieldName={field.name} />
    </div>
  );
};

export default InputMask;
