// apps
export const CITIZEN_MANAGEMENT = "citizen_management";

export const VACCINATION_MANAGEMENT = "vaccination_management";

export const MEDICAL_FOLDER_MANAGEMNT = "medical_folder_management";

export const ROUTINE = "routine"

export const OCCASIONAL = "occasional"

export const SEASONAL = "seasonal"

export const ANTIRABIQUE = "rabies"


// modules
export const CHILDREN = "children";

export const SUBSCRIPTION = "subscription"

export const VACCINATION = "vaccination";

export const ALLERGY = "allergy";

export const GROWTH_METRICS = "growth_metrics";

export const HEAD_CIRCUMFERENCE_METRICS = "head_circumference_metrics";

export const MEDICAL_FOLDER = "medical_folder";

export const DASHBOARD = "dashboard";
export const DCI = "dci";
export const MAPI = "mapi";
export const SPECIALTIE = "specialtie";
