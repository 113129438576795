import { ERROR_CODES, VALIDATION_ERROR_CODES } from '../constants'
import { isArray, isEmpty, isPlainObject,has } from 'lodash'
import { convertToSnakeCase } from './ObjectHelpers'

export class ForbiddenError extends Error {
}

export class PermissionDeniedError extends ForbiddenError {
  constructor (message) {
    super(message)
    this.name = 'PermissionDenied'
  }
}

export class AuthenticationError extends ForbiddenError {
  constructor (message) {
    super(message)
    this.name = 'AuthenticationError'
  }
}

export const formatFormError = (fields, data) => {
  let errors = {}

  if (!isPlainObject(data)) {
    return;
  }

    Object.keys(data).forEach((fieldName) => {
      if (isArray(data[fieldName])) {
        data[fieldName].forEach((obj) => {
          if (isPlainObject(obj) && Object.keys(obj).every(key => !isArray(obj[key]))) {
            errors = Object.assign(errors, getErrorMsg(fields, fieldName, obj.code, obj.message))
          } else if (isPlainObject(errors) && fieldName in errors) {
            errors[fieldName].push(formatFormError(obj))
          } else {
            errors[fieldName] = [formatFormError(obj)]
          }
        })
      } else if (isPlainObject(data[fieldName]) && isEmpty(data[fieldName])) {
        errors[fieldName] = [formatFormError(data[fieldName])]
      }
    })
  
  return errors
}

const getErrorMsg = (fields, fieldName, code, message) => {
  const errors = {}
  if (fieldName === 'nonFieldErrors') {
    fields.forEach((field) => {
      if (message.includes(convertToSnakeCase(field.name))) {
        errors[field.name] = VALIDATION_ERROR_CODES[code] || 'ERROR.CODE.VALIDATION.INVALID'
      }
    })
  } else {
    errors[fieldName] = VALIDATION_ERROR_CODES[code] || 'ERROR.CODE.VALIDATION.INVALID'
  }
  return errors
}

export const formatErrorMessage = (error) => {
  const { statusCode, data = {},status = undefined} = error
  console.log(data);
  const { errorCode = undefined } = data

  if (statusCode === 400 && errorCode && !data.includes("Invalid height metric") && !data.includes("Invalid perimeter metric")) {
    return 'ERROR.CODE.FORM_FAILED'
  }
  else if ( status === 400 && (data.includes("Wrong password."))) {
    return "AUTH.FORGOT.ERROR" 
  }
  else if ( statusCode === 400 && (data.includes("Invalid height metric") || data.includes("Invalid perimeter metric"))) {
    return "MEDICAL.FOLDER.ERROR"
  }
    else if ( data.includes("You have to vaccinate the previous vaccinations.")) {
    return "VACCINATION.ANTIRAB.PREVIOUS.ERROR"
  }
  else if ( status === 400 && (data.includes("File size exceeds the limit."))) {
    return "ERROR.INVALID_FILE_SIZE"
  }
  else if ( status === 400 &&(data.includes("Invalid PDF content.")||data.includes("Invalid file type.") || data.includes("Invalid image content."))) {
    return "ERROR.INVALID_FILE"
  }
  else if ( status === 400 && (data.includes("JO doit etre fait"))) {
    return "ERROR.FIRST_DOSE_VACCINATION_NEEDED"
  }
  else if ( status === 400 && (data.includes("sheet not fount"))) {
    return "ERROR.SHEET_NOT_FUND"
  }
  else if ( status === 400 && (data.includes("PR cannot be updated"))) {
    return "ERROR.PR_CANNOT_BE_UPDATED"
  }
  else if ( status === 400 && (data.includes("update only etat_animal_j0_j7"))) {
    return "ERROR.UPDATE_ONLY_ETAT_ANIMAL_J0_J7"
  }
  else if ( status === 400 && (data.includes("update only etat_animal_j7_j14"))) {
    return "ERROR.UPDATE_ONLY_ETAT_ANIMAL_J7_J14"
  }
  else if ( status === 400 && (data.includes("J3 non fait"))) {
    return "ERROR.J3_NON_FAIT"
  }
  else if ( status === 400 && (data.includes("sheet A1 open more than 14days"))) {
    return "ERROR.SHEET_A1_OPEN_MORE_THAN_14DAYS"
  }
  else if ( status === 400 && (data.includes("sheet A2 open more than 14days"))) {
    return "ERROR.SHEET_A2_OPEN_MORE_THAN_14DAYS"
  }
  else if ( status === 504 && (data.includes("The request timed out"))) {
    return "Backend service request timed out"
  }

  return ERROR_CODES[(errorCode || statusCode)] || 'ERROR.CODE.INTERNAL_ERROR'
}


export const formatMessageError = (error) => {
  if (!error) return 
  if (!error.response) return
  const {response:{data}} = error 
  if (has(data,"error")){
    return error.response.data.error;
  }
  else if (has(data,"detail")) {
    return error.response.data.detail;
  }
  else {
    return error.response.data
  }
}