import { ACTIONS } from "../constants";

const initialState = {
  newVaccination: {},
  vaccinations: [],
  vaccinesList: [],
  AntirabiqueVaccineList : [],
  ficheAntirabiqueList:[],
  antirabiqueVaccine : {},
  vaccinesOccationalList : [],
  vaccinesSeasonList :[],
  vaccinesAntirabiesList :[],
  searchResults: [],
  vaccination: {},
  ficheAntirabique : {},
  ficheId:null,
  totalSize: 0,
  evaxNumber: null,
  antirabProtocol:null,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isCreated: false,
    isPostponed: false,
    isUpdated: false,
    isDeleted: false,
    isUpdatedViccinAntirabies: false,

  },
  error: null,
  vaccinationRabbitAbsenceKpi:[]
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_VACCINATION: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
        vaccination: {},
        ficheAntirabique:{},
        vaccinations: [],
        // vaccinesList: [],
        antirabiqueVaccine : {},
        // vaccinesAntirabiesList:[],
        //ficheId:null,
        growthItem: {},
        headCircumferenceItem: {},
      };
    }

    case ACTIONS.CLEAR_ANTIRABIQUE_VACCINATION: {
      return {
        ...state,
        antirabiqueVaccine:{},
        vaccinesAntirabiesList:[],
        antirabProtocol:null,
  
      };
    }

    case ACTIONS.CLEAR_ANTIRABIQUE_SUCCESS:{
      return    {...state,success:initialState.success}
    }

    case ACTIONS.FETCH_ROUTINE_VACCINATIONS_LIST_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_ROUTINE_VACCINATIONS_LIST_SUCCEDED: {
      // console.log(payload)
      const { count, results } = payload;
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        totalSize: count,
        vaccinesList: results,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_ROUTINE_VACCINATIONS_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }


    case ACTIONS.FETCH_OCCATIONAL_VACCINATIONS_LIST_INIT: {
      return { ...state, isFetching: true,  error: null };
    }
    case ACTIONS.FETCH_OCCATIONAL_VACCINATIONS_LIST_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        totalSize: count,
        vaccinesOccationalList: results,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_OCCATIONAL_VACCINATIONS_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }


    case ACTIONS.FETCH_SEASON_VACCINATIONS_LIST_INIT: {
      return { ...state, isFetching: true,  error: null  , vaccinesSeasonList : []};
    }
    case ACTIONS.FETCH_SEASON_VACCINATIONS_LIST_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        totalSize: count,
        vaccinesSeasonList: results,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_SEASON_VACCINATIONS_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    

    case ACTIONS.CREATE_NEW_VACCINATION_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_NEW_VACCINATION_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        vaccinesList: state.vaccinesList.concat(payload),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_NEW_VACCINATION_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }


    case ACTIONS.CREATE_NEW_OCCATIONAL_VACCINATIONS_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_NEW_OCCATIONAL_VACCINATIONS_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        vaccinesList: state.vaccinesList.concat(payload),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_NEW_OCCATIONAL_VACCINATIONS_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }



    case ACTIONS.CREATE_NEW_SEASON_VACCINATIONS_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_NEW_SEASON_VACCINATIONS_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        vaccinesList: state.vaccinesList.concat(payload),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_NEW_SEASON_VACCINATIONS_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.EDIT_VACCINATION_OCCATIONAL_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_VACCINATION_OCCATIONAL_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        evaxNumber: payload?.evaxNumber,
        vaccinesList: state.vaccinesOccationalList.map((vaccine) =>
          vaccine.id === payload.vaccine ? payload : vaccine
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_VACCINATION_OCCATIONAL_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.EDIT_VACCINATION_SEASON_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_VACCINATION_SEASON_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        evaxNumber: payload?.evaxNumber,
        vaccinesList: state.vaccinesSeasonList.map((vaccine) =>
          vaccine.id === payload.vaccine ? payload : vaccine
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_VACCINATION_SEASON_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }


    case ACTIONS.VACCINATION_ANTIRABIQUE_DETAILS_INIT: {
      return { ...state, isFetching: true, error: null,antirabiqueVaccine:{} };
    }
    case ACTIONS.VACCINATION_ANTIRABIQUE_DETAILS_SUCCEDED: {
      // console.log(payload)
      return {
        ...state,
        isLoading: false,
        antirabiqueVaccine: {...payload,dateVaccination:payload.dateVaccination.split("-").reverse().join("/"),},
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.VACCINATION_ANTIRABIQUE_DETAILS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }



    

    case ACTIONS.EDIT_VACCINATION_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_VACCINATION_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        evaxNumber: payload?.evaxNumber,
        vaccinesList: state.vaccinesList.map((vaccine) =>
          vaccine.id === payload.vaccine ? payload : vaccine
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_VACCINATION_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.FETCH_VACCINATION_INIT: {
      return { ...state, isLoading: true, vaccination: null, error: null };
    }
    case ACTIONS.FETCH_VACCINATION_SUCCEDED: {
      console.log(payload)
      return { ...state, vaccination: payload, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }


  
    case ACTIONS.FETCH_SEASON_VACCINATION_INIT: {
      return { ...state, isLoading: true, vaccination: null, error: null };
    }
    case ACTIONS.FETCH_SEASON_VACCINATION_SUCCEDED: {
      return { ...state, vaccination: payload, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_SEASON_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.FETCH_OCCATIONAL_VACCINATION_INIT: {
      return { ...state, isLoading: true, vaccination: null, error: null };
    }
    case ACTIONS.FETCH_OCCATIONAL_VACCINATION_SUCCEDED: {
      return { ...state, vaccination: payload, isLoading: false, error: null };
    }
    case ACTIONS.FETCH_OCCATIONAL_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.POSTPONE_VACCINATION_INIT: {
      return { ...state, isLoading: true, vaccination: null, error: null };
    }
    case ACTIONS.POSTPONE_VACCINATION_SUCCEDED: {
      return {
        ...state,
        vaccination: null,
        isLoading: false,
        vaccinesList: state.vaccinesList.map((vaccine) =>
          vaccine.id === payload.id
            ? {
                ...vaccine,
                date: payload.date.split("-").reverse().join("/"),
                vaccineDate: payload.date.split("-").reverse().join("/"),
              }
            : vaccine
        ),
        error: null,
        success: { ...state.success, isPostponed: true },
      };
    }
    case ACTIONS.POSTPONE_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    case ACTIONS.CANCEL_VACCINATION_INIT: {
      return {
        ...state,
        isLoading: true,
        success: initialState.success,
        error: null,
      };
    }
    case ACTIONS.CANCEL_VACCINATION_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isDeleted: true },
        isLoading: false,
        vaccinesList : state.vaccinesList.filter((vaccine)=>vaccine.id !== payload.id),
        error: null,
      };
    }
    case ACTIONS.VACCINATION_ANTIRABIQUE_LIST_INIT: {
      return { ...state, isFetching: true,  error: null  , ficheAntirabiqueList : []};
    }
    // fiche antirabique
    case ACTIONS.VACCINATION_ANTIRABIQUE_LIST_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        totalSize: count,
        ficheAntirabiqueList: results,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.VACCINATION_ANTIRABIQUE_LIST_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_SUCCEDED: {

      const {results , protocole, ficheId} =  payload
      return {
        ...state,
        success: { ...state.success, isCreated: true },
        antirabProtocol: protocole,
        ficheAntirabiqueList: state.ficheAntirabiqueList.concat(results),
        vaccinesAntirabiesList : results,
        ficheId:ficheId,
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.CREATE_NEW_FICHE_ANTIRABIES_VACCINATION_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.EDIT_FICHE_ANTIRABIES_VACCINATION_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_FICHE_ANTIRABIES_VACCINATION_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        evaxNumber: payload?.evaxNumber,
        ficheAntirabiqueList: state.ficheAntirabiqueList.map((vaccine) =>
          vaccine.id === payload.vaccine ? payload : vaccine
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_FICHE_ANTIRABIES_VACCINATION_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false,ficheAntirabiqueList: state.ficheAntirabiqueList.concat(payload), };
    }



    case ACTIONS.UPDATE_ANTIRABIES_VACCINATION_SCHEMA_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.UPDATE_ANTIRABIES_VACCINATION_SCHEMA_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isUpdatedViccinAntirabies: true },
        evaxNumber: payload?.evaxNumber,
        vaccinesAntirabiesList: state.vaccinesAntirabiesList.map((vaccine) =>
          vaccine.id === payload.id ? payload : vaccine
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.UPDATE_ANTIRABIES_VACCINATION_SCHEMA_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false, };
    }

    
    case ACTIONS.SAVE_LOCATION_STATE:{
      return {
        ...state,
        state: action.payload,
      };
    }


    case ACTIONS.FETCH_FICHE_ANTIRABIES_VACCINATION_INIT: {
      return { ...state, isLoading: true, ficheAntirabique: null, error: null };
    }
    case ACTIONS.FETCH_FICHE_ANTIRABIES_VACCINATION_SUCCEDED: {
      return { ...state, ficheAntirabique: payload, 
        isLoading: false, error: null };
    }
    case ACTIONS.FETCH_FICHE_ANTIRABIES_VACCINATION_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }


    case ACTIONS.GET_ANTIRABIES_VACCINATION_LIST_INIT: {
      return { ...state, isLoading: true, ficheAntirabique: null, error: null };
    }
    case ACTIONS.GET_ANTIRABIES_VACCINATION_LIST_SUCCEDED: {
      const { protocole, results, poidsCitoyen } = payload;
      console.log(payload)
      return { ...state, vaccinesAntirabiesList: results,  antirabProtocol:protocole, poidsCitoyen:poidsCitoyen, isLoading: false, error: null };
    }
    case ACTIONS.GET_ANTIRABIES_VACCINATION_LIST_FAILED: {
      return { ...state, isLoading: false, error: payload };
    }

    


    case ACTIONS.CLEAR_CHILDREN: {
      return { ...state, isLoading: false, error: null, searchResults: [] };
    }
    case ACTIONS.CLEAR_FICHE_ANTIRABIQUE: {
      return { ...state, ficheId: null}
    }

    case ACTIONS.VACCINATION_RABITS_ABSENT_KPI_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.VACCINATION_RABITS_ABSENT_KPI_SUCCEDED: {
      const { count, results } = payload;
      return { ...state, 
        vaccinationRabbitAbsenceKpi: results,
        totalSize:count,
        success:true,
        isFetching: false,
        error: null 
        };
    }
    case ACTIONS.VACCINATION_RABITS_ABSENT_KPI_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    default: {
      return state;
    }
  }
  
};




