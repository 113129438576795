import { useEffect, useState } from "react"
import {getAttr} from "../../../../../../helpers"
import { isString, isArray, isEmpty } from "lodash"


const useHideAndShowField = ({ formik, fieldName,formRepeaterRelyOn, hide = false,hideProperty = false, hideOn, condition = false, hideOnFields = [], hideOnValue="", soft=false,showOnValue,showOnValueDefault }) => {

  const [show, setShow] = useState(hide)


  useEffect(() => {
    if (isString(hideOn)){
      let field = getAttr(formik.values, hideOn, false)
      let formattedCondition = condition
      if (typeof condition === "boolean") {
        formattedCondition = ( Boolean(condition)).toString()
      }
      if (typeof field === "boolean") {
        field = (Boolean(field)).toString()
      }
      let checkCondition = Boolean(isArray(formattedCondition) ? !formattedCondition.includes(field) : field !== formattedCondition)
      if (isArray(field)){
        checkCondition = !field.includes(formattedCondition);
      }
      setShow(checkCondition)
      if (hideProperty){
        setShow(checkCondition || hideProperty)
      }
    }

    //  eslint-disable-next-line
  }, [formik])


  useEffect(() => {
    if (show) {
      formik.setFieldValue(fieldName, hideOnValue)
      formik.setTouched({},false)
    }
    else if (showOnValue && !show){
      formik.setFieldValue(fieldName, showOnValue)
      formik.setTouched({},false)

    }
    else if (!show && showOnValueDefault && !getAttr(formik.values,fieldName) )
      {
    formik.setFieldValue(fieldName, showOnValueDefault)
    formik.setTouched({},false)
     }
     
    //  eslint-disable-next-line
  }, [show])



  useEffect(() => {

    if (!isEmpty(hideOnFields) && isArray(hideOnFields) && !hide){
      const conditions = []
      hideOnFields.forEach(({ hideOn, condition, notEqual=false }) => {
        if (isString(hideOn)){
          let formattedCondition = condition
          if (typeof condition === "boolean") {
            formattedCondition = (Boolean(condition)).toString()
          }
          let field = getAttr(formik.values, hideOn, false)
          if (typeof field === "boolean") {
            field = (Boolean(field)).toString()
          }
          let  checkCondition = isArray(formattedCondition) ? !formattedCondition.includes(field) : field !== formattedCondition
          if (notEqual && field) {
            checkCondition = field === condition
          }
          conditions.push(checkCondition)
          
        }
      })
    
        if (soft ? conditions.some((value) => value === false) : conditions.every((value) => value === false)){
          setShow(false)
        }else{
          setShow(true)
        }
      
    }


    if (!isEmpty(hideOnFields) && isArray(hideOnFields) && formRepeaterRelyOn){
     
      const conditions = []
      hideOnFields.forEach(({ hideOn, condition, notEqual = false }) => {
        const [formRepeaterName, index] = fieldName.split('.');
    
        if (isString(hideOn)) {
            let formattedCondition = condition;
            if (typeof condition === "boolean") {
                formattedCondition = condition.toString();
            }
    
            let field = formRepeaterName + "[" + index + "]." + hideOn;
            let test = getAttr(formik.values,field,null)

            let checkCondition;
            if (isArray(formattedCondition)) {
                checkCondition = !formattedCondition.includes(field);
            } else {
               
                let test = getAttr(formik.values,field,null)
                checkCondition = test == !formattedCondition;
            }
    
            if (notEqual && field) {
                checkCondition = test === condition;
            }
    
            conditions.push(checkCondition);
        }
    });
    
      // hideOnFields.forEach(({ hideOn, condition, notEqual }) => {
      //   const [formRepeaterName,index] = fieldName.split('.')
      //   if (isString(hideOn)){
      //     let formattedCondition = condition
      //     if (typeof condition === "boolean") {
      //       formattedCondition = (Boolean(condition)).toString()
      //     }
      //     //let field = getAttr(formik.values, hideOn, false)
      //     let field = formRepeaterName+"["+index+"]."+hideOn;
      //     let  checkCondition = isArray(formattedCondition) ? !formattedCondition.includes(field) : field !== formattedCondition
      //     if (notEqual && field) {
      //       checkCondition = field === condition
      //     }
      //     conditions.push(checkCondition)
          
      //   }
      // })
      //console.log("conditions",conditions)
    //   if (soft ? conditions.some((value) => value === false) : conditions.every((value) => value === false)){
    //     setShow(false)
    //   }else{
    //     setShow(true)
    //   }

      //console.log("conditions",conditions)
    const shouldHide = soft 
    ? conditions.some(value => value === false) 
    : conditions.every(value => value === false);


setShow(!shouldHide);

    }


    //  eslint-disable-next-line
  }, [formik, hideOnFields,soft])






  // useEffect(() => {
  //   if (!isEmpty(hideOnFields) && isArray(hideOnFields)){
  //     hideOnFields.some(({ hideOn, condition }) => {
  //       if (isString(hideOn)){
  //         const field = getAttr(formik.values, hideOn, false)
  //         const checkCondition = Boolean(isArray(condition) ? !condition.includes(field) : field !== condition)
  //         if (checkCondition){
  //           setShow(checkCondition)
  //           return true
  //         }
  //       }
  //       return false
  //     })
  //   }

  //   //  eslint-disable-next-line
  // }, [formik])

  return show ? " d-none" : " "
}


export default useHideAndShowField
