import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SVG from "react-inlinesvg";
import moment from "moment";
import { useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { getLang, isRLTLang } from "./../../../../../i18n";
import useHideAndShowField from "./hooks/useHideAndShowField";
import useResizeHook from "./hooks/useResizeHook";
import { FieldError } from "./FieldError";
import "moment/locale/ar-tn";
import "moment/locale/fr";
import "./DatePickerFieldWithInput.css"
import { FR, AR } from "../../../../../constants";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import  {fr,arTN}  from 'date-fns/locale';
import { getAttr, toAbsoluteUrl } from "src/helpers";
import _ from "lodash";
import useResetDateRangePickerField from './hooks/useResetDateRangePickerField';
import useResetDatePickerWithInput from './hooks/useResetDatePickerWithInput';

const locale = {
  [FR]: "fr",
  [AR]: "ar-tn",
};
// Register the locales
registerLocale('fr', fr);
registerLocale('ar-tn', arTN);

const DatePickerFieldWithInput = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  relatedTo,
  changeDateValue,
  icon = true,
  isValidDate = () => true,
  errorValidation = () => "Invalid date format. Please use DD/MM/YYYY.",
  inputGroupClassName = "form-group",
  inputClassName = "form-control-date-picker form-control",
  type = "date",
  format = "dd/MM/yyyy",
  placeholder,
  size = 12,
  dateFormat = true,
  timeFormat = false,
  required = false,
  disabled = false,
  maxLength = 256,
  initialValue = null,
  rdv_sais_occas = false,
  initialValueChainedField = "",
  resetConditionValue = undefined,
  mapi=false,
  emit,
  eventName,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [manualInputValue, setManualInputValue] = useState(initialValue || "");
  const [isValid, setIsValid] = useState(true);
  const [customError, setCustomError] = useState(null);
  const intl = useIntl();
  const formik = useFormikContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const { value } = field;
  const [width, height] = useResizeHook();
  useResetDatePickerWithInput({ formik, fieldName:field.name,setSelectedDate,format:"DD/MM/YYYY"})


  let manualDateValue=""
  const iconStyle = isRLTLang()
    ? {
        position: "absolute",
        height: "1.125rem",
        minWidth: "1.25rem",
        top: "calc(100% - 1.875rem)",
        right: "calc(100% - 2.5rem)",
        cursor: "pointer",
      }
    : {
        position: "absolute",
        height: "1.125rem",
        minWidth: "1.25rem",
        top: "calc(100% - 1.875rem)",
        left: "calc(100% - 2.5rem)",
        cursor: "pointer",
      };

  const adjustDatePickerPosition = () => {
    let elements = [...document.getElementsByClassName("rdt")];
    elements.forEach((element) => {
      const elementRect = element.getBoundingClientRect();
      const spaceBelow = window.innerHeight - elementRect.bottom;
      let popElements = [...document.getElementsByClassName("rdtPicker")];
      popElements.forEach((popElement) => {
        if (spaceBelow < 250) {
          popElement.classList.add("flip-Position");
        } else {
          popElement.classList.remove("flip-Position");
        }
      });
    });
  };


  const handleInputChange = (e) => {
    const value = e.target.value;
    console.log("handleInputChange", {value});
    setCustomError(null)
    const format = "DD/MM/YYYY"; // Expected date format
    const date = moment(value, format, true); // Validate input against the format
    if (date.isValid() && date.format(format) === value) {
      // If valid and matches the exact format, update the state
      setManualInputValue(value);
      manualDateValue=value
      setIsValid(true);
      if (relatedTo && changeDateValue) {
        form.setFieldValue(relatedTo, changeDateValue(date));
      }
      form.setFieldValue(field.name, date.format(format));
    } else {
      // If invalid or format doesn't match, show error and reset the state
      setManualInputValue(value);
      manualDateValue=value
      setIsValid(false);
      form.setFieldValue(field.name, ""); // Clear the field value
    }
  };
  
  


  const handleDateChange = (date) => {
    console.log("handleDateChange - date:", {date,manualDateValue});
    
    const format = "DD/MM/YYYY"; // Expected date format
    const manualDate = moment(manualDateValue, format, true); 
    if (date && isValidDate(moment(date),formik.values) && ( (manualDateValue && isValidDate(moment(manualDate),formik.values)) || !manualDateValue  ) ) {
      const formattedDate = moment(date).format("DD/MM/yyyy");
      setSelectedDate(date);
      setManualInputValue(formattedDate);
      manualDateValue=formattedDate
      setIsValid(true);
      if (relatedTo && changeDateValue) {
        form.setFieldValue(relatedTo, changeDateValue(moment(date)));
      }
      form.setFieldValue(field.name, formattedDate);    
      if(emit){
        emit({type:eventName,payload:moment(date).format(format)})
      }
  
    } else if(manualDate && !manualDate.isValid() ) {
      setSelectedDate(null);
      setManualInputValue("");
      manualDateValue=""
      setIsValid(false);
      form.setFieldValue(field.name, "");
    } else{
      setSelectedDate(null);
      setManualInputValue("");
      manualDateValue=""
      setIsValid(false);
      form.setFieldValue(field.name, "");
    }
  };
  
  

  const handleInputBlur = (e) => {
    console.log("handleInputBlur:", {manualInputValue,value,targetValue:e.target.value});

    // If no manual input value exists, do not run the validation
    if(!e.target.value){
      setCustomError(null);
    }else{ 
      const date = moment(e.target.value, "DD/MM/yyyy", true);
      console.log("handleInputBlur - date:", date, date.isValid(),isValidDate(date,formik.values));
      if (!date.isValid() || !value || value?.lenght < 9) {
        setManualInputValue("");
        manualDateValue=""
        setIsValid(false)
        setCustomError( intl.formatMessage({ id: "Invalid date format. Please use DD/MM/YYYY." }));
        setSelectedDate(null); // Clear the selected date
        form.setFieldValue(field.name, "");
      } else if (!isValidDate(date,formik.values)) {
        setManualInputValue("");
        manualDateValue=""
        setIsValid(false);
        setCustomError(()=>  errorValidation(date,formik.values));
        setSelectedDate(null); // Clear the selected date
        form.setFieldValue(field.name, "");
      }else {
        setIsValid(true);
        setCustomError(null);
        setSelectedDate(date.toDate()); // Update the DatePicker
      }
    }
  };

  const radioFields = ['radio', 'parentType', 'inscriptionNumberType'];

  useEffect(() => {
    const hasRadioChanged = radioFields.some(fieldName => {
      return formik?.values?.[fieldName];
    });

    if (hasRadioChanged) {
      console.log("****** RADIO component changed ******");
      setCustomError(null); // Clear custom error
      setManualInputValue("");
      manualDateValue=""
      setSelectedDate(null); // Clear the selected date
      form.setFieldValue(field.name, "");
    }
  },[formik?.values?.radio, formik?.values?.parentType, formik?.values?.inscriptionNumberType]); // Log the value whenever resetError changes

  useEffect(() => {
    adjustDatePickerPosition();
  }, [height, width]);

  useEffect(() => {
    const date = value ? moment(value, "DD/MM/yyyy", true) : null ;
    setSelectedDate(date ? date.toDate() : null);
  }, []);


  useEffect(() => {
    if (!_.isEmpty(initialValueChainedField) && resetConditionValue) {
        let initValChainedField = getAttr(formik.values, initialValueChainedField, undefined) 
        if(!initValChainedField){
          form.setFieldValue(field.name, "");
          formik.setFieldValue(field.name, "");
          setSelectedDate(undefined);
          setManualInputValue("");
          manualDateValue=""
          setIsDisabled(false);
        }else if(getAttr(formik.values, initialValueChainedField, undefined) === resetConditionValue){
          form.setFieldValue(field.name, "");
          formik.setFieldValue(field.name, "");
          setSelectedDate(undefined);
          setManualInputValue("");
          manualDateValue=""
          setIsDisabled(false);
        }else{
          form.setFieldValue(field.name, initialValue);
          formik.setFieldValue(field.name, initialValue);
          setIsDisabled(true);
        }
    }
    setCustomError(null);
  }, [getAttr(formik.values, initialValueChainedField)]); 

  useEffect(() => {
    if (mapi) {
      if (value) {
        let date = moment(value, "DD/MM/yyyy");
        if (date.isValid()) {
          setSelectedDate(date.toDate());
          const formattedDate = moment(date).format("DD/MM/yyyy");
          setManualInputValue(formattedDate);
          manualDateValue=formattedDate
        } else {
          setManualInputValue(value);
          manualDateValue=value
        }
      } else {
        // Set initial value when field is empty
        setManualInputValue(initialValue);
        manualDateValue=initialValue
        setSelectedDate(undefined);
      }
    }else{
      let date;
      let initValChainedField = getAttr(formik.values, initialValueChainedField, undefined) 
      if(!value || ((!rdv_sais_occas || !value ) && ( !initValChainedField || _.isEmpty(initValChainedField))) ) return;

      
      if (_.isString(value) && !_.isEmpty(value)) {
        date = moment(value, "DD/MM/yyyy");
      }
      if (_.isDate(value)) {
        date = moment(value);
      }
      if (date) {
        setSelectedDate(date.toDate());
        const formattedDate = moment(date).format("DD/MM/yyyy");
        setManualInputValue(formattedDate);
        manualDateValue=formattedDate
        form.setFieldValue(field.name, formattedDate);
        if (relatedTo && changeDateValue) form.setFieldValue(relatedTo, changeDateValue(moment(date)));
      } else {
        setSelectedDate(undefined);
        setManualInputValue(value);
        manualDateValue=value
        const formattedDate = moment(initialValue).format("DD/MM/yyyy");
        form.setFieldValue(field.name, initialValue ? formattedDate : null);
      }
    }
  }, [mapi, value, format, initialValue]);
  
  
  const fieldCSSClasses = form.errors[field.name] && form.touched[field.name] ? "is-invalid" : "";
  const dir = isRLTLang() ? "rtl" : "ltr";
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });

  const filterDate = (date) => {
    return  isValidDate(moment(date),formik.values);
  };

  // Determine the current locale
  const currentLocale = locale[getLang()] || 'fr';
  setDefaultLocale(currentLocale);

  return (
    <div dir={dir} className={`${inputGroupClassName} ${conditionalShowAndHideClassName} col-lg-${size} datepickerWithInput`}  style={{  }}>

      {label && (
        <label className="custom-label-style">
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <div className="position-relative" style={{  width: "100%" }}>
        {icon && (
          <SVG
            src={toAbsoluteUrl("/media/svg/dateIcon.svg")}
            style={iconStyle}
            onClick={() => document.getElementById('date-input').focus()} // To open datepicker on icon click
          />
        )}
        <DatePicker
          id="date-input"
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat={dateFormat ? format : ""}
          placeholderText={isRLTLang() ? "سنة/شهر/يوم" : "JJ/MM/AAAA"}

          className={`date-picker-full-width ${inputClassName} ${fieldCSSClasses} ${(disabled || isDisabled)&& `disabled`}`}
          
          locale={currentLocale}
          disabled={disabled || isDisabled}
          onChangeRaw={handleInputChange}
          onBlur={handleInputBlur}
          filterDate={filterDate} // Disable invalid dates
          showYearDropdown // Enable year dropdown
          showMonthDropdown // Enable month dropdown
          scrollableYearDropdown // Make year dropdown scrollable
          yearDropdownItemNumber={100} // Show 100 years in the dropdown
          dropdownMode="select" // Set dropdown mode to select
          
          style={{
            width: "100%",
            padding: ".625rem",
            textAlign: "start",
            backgroundColor: "#FAFAFA",
            height: 40,
            borderColor: isValid ? '' : 'red',
            background: 'yellow !important',
          }}
          autoComplete="off"
          {...props}
        />
      </div>
      {!isValid && <div className="invalid-feedback">{customError}</div>}
      {!customError &&<FieldError fieldName={field.name} />}
    </div>
  );


 
};

export default DatePickerFieldWithInput;
